<template>
  <section>
    <section class="top">
      <div>
        <p class="std_title">
          Student
        </p>
        <p class="std_subtitle">
          List Of Students
        </p>
      </div>
      <div>
        <div class="top_search">
          <div v-if="(radioGroup === 'class')">
            <VSelect
              v-model="filterType"
              :hide-details="true"
              :items="students"
              item-text="class"
              item-value="class"
              label="Sort By Class"
              solo
              flat
            />
          </div>
          <div v-if="(radioGroup === 'id')">
            <VTextField
              v-model="filterType"
              :hide-details="true"
              label="Search By ID"
              append-icon="search"
              solo
              flat
            />
          </div>
          <div v-if="(radioGroup === 'name')">
            <VTextField
              v-model="filterType"
              :hide-details="true"
              label="Search By Name"
              append-icon="search"
              solo
              flat
            />
          </div>
        </div>
      </div>
      <div class="top_btn">
        <VBtn
          icon
          large
          class="shadow mr-0"
          color="white"
          @click="filterModal = true"
        >
          <VIcon>
            filter_list
          </VIcon>
        </VBtn>
        <VBtn
          round
          large
          class="shadow"
          color="white"
          to="student/new"
        >
          New Student
        </VBtn>
      </div>
    </section>
    <section>
      <div class="content">
        <div v-if="(radioGroup === 'name')">
          <div class="std_grid">
            <!-- Start Looping Data -->
            <div
              v-for="student in filterName"
              :key="student.id"
            >
              <VCard class="std_card shadow">
                <VList two-line>
                  <VListTile>
                    <VListTileAvatar color="primary">
                      <span class="white--text">
                        <b> {{ student.name.charAt(0).toUpperCase() }} </b>
                      </span>
                    </VListTileAvatar>
                    <VListTileContent>
                      <VListTileTitle> {{ student.name }} </VListTileTitle>
                      <VListTileSubTitle class="subtxt">
                        {{ student.admNo }}
                      </VListTileSubTitle>
                    </VListTileContent>
                    <VListTileAction>
                      <VListTileActionText class="subtxt">
                        CL : {{ student.class }}
                      </VListTileActionText>

                      <VBtn
                        icon
                        color="white"
                        @click="viewStd(student.id)"
                      >
                        <VIcon color="secondary">
                          visibility
                        </VIcon>
                      </VBtn>
                    </VListTileAction>
                  </VListTile>
                </VList>
              </VCard>
            </div>
          </div>
        </div>
        <div v-if="(radioGroup === 'class')">
          <div class="std_grid">
            <!-- Start Looping Data -->
            <div
              v-for="student in filterClass"
              :key="student.id"
            >
              <VCard class="std_card shadow">
                <VList two-line>
                  <VListTile>
                    <VListTileAvatar color="primary">
                      <span class="white--text">
                        <b> {{ student.name.charAt(0).toUpperCase() }} </b>
                      </span>
                    </VListTileAvatar>
                    <VListTileContent>
                      <VListTileTitle> {{ student.name }} </VListTileTitle>
                      <VListTileSubTitle class="subtxt">
                        {{ student.admNo }}
                      </VListTileSubTitle>
                    </VListTileContent>
                    <VListTileAction>
                      <VListTileActionText class="subtxt">
                        CL : {{ student.class }}
                      </VListTileActionText>

                      <VBtn
                        icon
                        color="white"
                        @click="viewStd(student.id)"
                      >
                        <VIcon color="secondary">
                          visibility
                        </VIcon>
                      </VBtn>
                    </VListTileAction>
                  </VListTile>
                </VList>
              </VCard>
            </div>
          </div>
        </div>
        <div v-if="(radioGroup === 'id')">
          <div class="std_grid">
            <!-- Start Looping Data -->
            <div
              v-for="student in filterID"
              :key="student.id"
            >
              <VCard class="std_card shadow">
                <VList two-line>
                  <VListTile>
                    <VListTileAvatar color="primary">
                      <span class="white--text">
                        <b> {{ student.name.charAt(0).toUpperCase() }} </b>
                      </span>
                    </VListTileAvatar>
                    <VListTileContent>
                      <VListTileTitle> {{ student.name }} </VListTileTitle>
                      <VListTileSubTitle class="subtxt">
                        {{ student.admNo }}
                      </VListTileSubTitle>
                    </VListTileContent>
                    <VListTileAction>
                      <VListTileActionText class="subtxt">
                        CL : {{ student.class }}
                      </VListTileActionText>

                      <VBtn
                        icon
                        color="white"
                        @click="viewStd(student.id)"
                      >
                        <VIcon color="secondary">
                          visibility
                        </VIcon>
                      </VBtn>
                    </VListTileAction>
                  </VListTile>
                </VList>
              </VCard>
            </div>
          </div>
        </div>
      </div>
    </section>
    <VDialog
      v-model="filterModal"
      persistent
      max-width="290"
    >
      <VCard class="elevation-20 filter_card">
        <div class="filter_card_container">
          <p>
            Filter Students
          </p>
          <VRadioGroup
            v-model="radioGroup"
            :hide-details="true"
          >
            <VRadio
              value="name"
              color="primary"
            >
              <template v-slot:label>
                <div class="filter_txt">
                  Search By Name
                </div>
              </template>
            </VRadio>
            <VRadio
              value="id"
              color="primary"
            >
              <template v-slot:label>
                <div class="filter_txt">
                  Search By FLA ID
                </div>
              </template>
            </VRadio>
            <VRadio
              value="class"
              color="primary"
            >
              <template v-slot:label>
                <div class="filter_txt">
                  Sort By Class
                </div>
              </template>
            </VRadio>
          </VRadioGroup>
        </div>
        <VCardActions>
          <VSpacer />
          <div class="pt-2 pr-2 pl-0 pb-2">
            <VBtn
              class="ma-0"
              color="primary"
              flat
              round
              depressed
              @click="filterModal = false"
            >
              Done
            </VBtn>
          </div>
        </VCardActions>
      </VCard>
    </VDialog>

    <VDialog
      v-model="isLoading"
      width="300"
      persistent
    >
      <VCard
        v-if="loadStatus === 'loading'"
        class="notification_card elevation-20"
      >
        <p>Loading</p>
        <span>Fetching Data, Please Wait...</span>
        <div class="notification_ico">
          <VProgressCircular
            indeterminate
            color="primary"
            :size="30"
            :width="3"
          />
        </div>
      </VCard>
      <VCard
        v-if="loadStatus === 'netfail'"
        class="notification_card elevation-20"
      >
        <p>Internet Issue</p>
        <span>Retrying In {{ retry }} Seconds</span>
        <div class="notification_ico">
          <VProgressCircular
            indeterminate
            color="red"
            :size="30"
            :width="3"
          />
        </div>
      </VCard>
      <VCard
        v-if="loadStatus === 'fail'"
        class="notification_card elevation-20"
      >
        <p>Error</p>
        <span>An Error Occured</span>
        <div class="notification_ico">
          <VBtn
            depressed
            round
            color="primary"
            @click="reloadPage()"
          >
            Reload
          </VBtn>
        </div>
      </VCard>
    </VDialog>
  </section>
</template>

<script>
import axios from 'axios';
import api from '../../conf/api';

export default {
  data: () => ({
    isLoading: true,
    loadStatus: '',
    retry: 0,
    students: [],
    filterModal: false,
    radioGroup: 'name',
    filterType: '',
  }),
  computed: {
    filterName() {
      return this.students.filter(student => student.name.toLowerCase()
        .match(this.filterType.toLowerCase()));
    },
    filterClass() {
      return this.students.filter(student => student.class.toLowerCase()
        .match(this.filterType.toLowerCase()));
    },
    filterID() {
      return this.students.filter(student => student.admNo.toLowerCase()
        .match(this.filterType.toLowerCase()));
    },
  },
  async beforeMount() {
    try {
      this.loadStatus = 'loading';
      await axios.get(`${api}/student/all`)
        .then((res) => {
          this.isLoading = false;
          if (res.data !== '') {
            this.students = res.data.data;
          }
        });
    } catch (e) {
      if (e.code === 'auth/network-request-failed') {
        this.loadStatus = 'netfail';
        this.retry = 10;
        setInterval(() => {
          this.retry -= 1;
        }, 1000);
        setTimeout(() => {
          window.location.reload();
        }, 9500);
      } else {
        this.loadStatus = 'fail';
      }
    }
  },
  methods: {
    reloadPage() {
      setTimeout(() => {
        window.location.reload();
      }, 400);
    },
    viewStd(id) {
      setTimeout(() => {
        this.$router.push(`/student/${id}`);
      }, 150);
    },
  },
};
</script>

<style lang="stylus" scoped>
@import '../../assets/stylus/Student/index';
</style>
